import {
  AfterViewChecked,
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  Optional,
  Output,
  SimpleChanges,
} from '@angular/core';
import { DatePickerComponent, DateTimePickerComponent, TimePickerComponent } from '@progress/kendo-angular-dateinputs';

@Directive({
  selector: '[cueFoldable]',
  standalone: true,
})
export class FoldableDirective implements AfterViewChecked {
  @Input() itemIdx: number;
  @Output() heightValueChanged: EventEmitter<number> = new EventEmitter();
  constructor(private elementRef: ElementRef) {}

  ngAfterViewChecked(): void {
    const height = this.elementRef.nativeElement.offsetHeight;
    this.heightValueChanged.emit(height);
    // const parentElement = document.getElementById('hiddablePart');
    //
    // if (height <= 50) {
    //   parentElement.classList.add('hidden');
    // } else {
    //   parentElement.classList.remove('hidden');
    // }
  }
}
