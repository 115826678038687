import { Component, HostBinding, Input, ChangeDetectionStrategy } from '@angular/core';
import { ImageStateService } from '../../services';

@Component({
  selector: 'cue-progressive-image',
  templateUrl: './progressive-image.component.html',
  styleUrls: ['./progressive-image.component.scss'],
  imports: [],
  providers: [ImageStateService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressiveImageComponent {
  @HostBinding('style.height')
  @Input()
  imgHeight: string;

  @HostBinding('style.max-height')
  @Input()
  imgMaxHeight: string;

  @HostBinding('style.width')
  @Input()
  imgWidth: string;
}
