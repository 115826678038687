import { inject, makeEnvironmentProviders, provideEnvironmentInitializer } from '@angular/core';
import { DatePipe } from '@angular/common';
import { PipesService } from './pipes.service';
import { DateTimeFormatPipe } from './date-time-format.pipe';
import { TimeFormatPipe } from './time-format.pipe';
import { DateFormatPipe } from './date-format.pipe';

export function providePipes(options?: { dateFormat?: () => string | undefined; timeFormat?: () => string | undefined }) {
  return makeEnvironmentProviders([
    PipesService,
    provideEnvironmentInitializer(() => {
      const service = inject(PipesService);
      if (options?.dateFormat) {
        const dateFormat = options.dateFormat();
        if (dateFormat != null) {
          service.dateFormat = dateFormat;
        }
      }
      if (options?.timeFormat) {
        const timeFormat = options.timeFormat();
        if (timeFormat != null) {
          service.timeFormat = timeFormat;
        }
      }
    }),
    DatePipe,
    DateTimeFormatPipe,
    TimeFormatPipe,
    DateFormatPipe,
  ]);
}
